import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dialog from '../components/Dialog';
import formatPrice from '../utils/formatPrice';
import Chevlink from '../components/Chevlink';
import 'es6-promise/auto'; // IE11
import axios from 'axios';
import striptags from 'striptags';
import { Banners } from '../components/Banners';
import notesProcessor from '../utils/notesProcessor';
import config from '../../config';
import Squares from '../components/Squares';
import ProductListItems from '../components/ProductListItems';
import factorySVG from '../../img/factory.svg';
import assemblyLine1SVG from '../../img/assembly-line-1.svg';
import deliveryVan2SVG from '../../img/delivery-van-2.svg';
import earthSVG from '../../img/earth.svg';
import Lightbulb1SVG from '../../img/lightbulb-1.svg';
import toolsSVG from '../../img/tools.svg';
import warehouse1SVG from '../../img/warehouse-1.svg';
import leafSVG from '../../img/leaf.svg';

class ProductDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            info: {},
            additionalInfo: [],
            prices: [],
            variants: [],
            showMoreNote2: false
        }
        this.reloadProductInfo = this.reloadProductInfo.bind(this);

    }

    componentDidUpdate(prevProps) {

        if ((!prevProps.product && this.props.product) || (this.props.product && (prevProps.product.id !== this.props.product.id))) {
            this.reloadProductInfo();
        }
    }

    reloadProductInfo() {
        let _this = this;
        this.setState({
            info: {},
            additionalInfo: [],
            prices: [],
            variants: [],
            showMoreNote2: false
        })
        window.history.replaceState({}, "Vyhledávání", '/' + config.searchPageName + '/' + this.props.productType + '/' + this.props.product.id);
        axios.get('/api/' + this.props.productType + "/" + this.props.product.id)
            .then((response) => {

                if (!_this.props.errorHandler.check(response)) return;

                _this.setState({
                    info: response.data.info,
                    additionalInfo: response.data.additionalInfo,
                    prices: response.data.prices,
                    variants: response.data.variants,
                    showMoreNote2: response.data.info.note2.length < 400 ? true : false
                })

            })
            .catch(function (error) {
                console.log(error);

            });
    }




    render() {

        if (!this.props.product) {
            return null;
        }

        const catObj = {
            id: this.state.info.cat_id,
            label: this.state.info.file
        }
        
        const co2Ranks = this.state.info ? [
            {
                rank: "A1-A3",
                label: 'Výroba',
                value: this.state.info.ENVI_A1TO3,
                svg: factorySVG,
            },
            {
                rank: "A4",
                label: 'Doprava',
                value: this.state.info.ENVI_A4,
                svg: deliveryVan2SVG,
            },
            {
                rank: "A5",
                label: 'Instalace',
                value: this.state.info.ENVI_A5,
                svg: toolsSVG,
            },
            {
                rank: "B",
                label: 'Použití',
                value: this.state.info.ENVI_B,
                svg: Lightbulb1SVG,
            },
            {
                rank: "C",
                label: 'Konec životnosti',
                value: this.state.info.ENVI_C,
                svg: warehouse1SVG,
            },
            {
                rank: "D",
                label: 'Potenciál 3R',
                value: this.state.info.ENVI_D,
                svg: assemblyLine1SVG
            },
        ] : [];
        
        return (
            <Dialog
                active={this.props.active}
                closeDialog={this.props.closeDialog}
                label="Detail položky"
                actions={[
                    {
                        label: "Zavřít",
                        onClick: this.props.closeDialog
                    }
                ]}
            >
                <div className="detail">
                    <div className="detail__main">
                        <table>
                            <tbody>
                                <tr><th>Kód:</th><td>{this.props.product.id}</td></tr>
                                <tr><th>Popis:</th><td className="detail__main__title">{striptags(this.props.product.title)}</td></tr>
                                <tr><th>Plný popis:</th><td>{this.state.info.description}</td></tr>
                                {this.state.info.vyrobce &&
                                    <tr><th>Výrobce:</th><td>{this.state.info.vyrobce}</td></tr>
                                }
                                {this.state.info.kod_vyrobce && this.state.info.odkaz_vyrobce &&
                                    <tr><th>Kód výrobce:</th><td><a href={this.state.info.odkaz_vyrobce} target="_blank">{this.state.info.kod_vyrobce}</a></td></tr>
                                }
                                {this.state.info.kod_vyrobce && !this.state.info.odkaz_vyrobce &&
                                    <tr><th>Kód výrobce:</th><td>{this.state.info.kod_vyrobce}</td></tr>
                                }
                                <tr><th>Mj:</th><td><span dangerouslySetInnerHTML={{ __html: this.props.product.unit }}></span></td></tr>
                                {this.state.additionalInfo.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <th>{item.title}:</th>
                                            <td>{item.value}</td>
                                        </tr>
                                    )
                                })}
                                <tr><th>Cena:</th><td><span className="detail__main__price">{formatPrice(this.props.product.price)}</span></td></tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <Banners
                        noImage
                        banners={this.props.banners}
                        closeBanner={this.props.closeBanner}
                    />
                    
                    {this.state.info && this.state.info.ENVI_X === 1 && (
                        <div className="detail__co2-banner">
                            <h3>
                                Potenciál globálního oteplování <span className="detail__co2-banner__title-leaf"><img src={leafSVG} alt="CO2" /></span>
                            </h3>
                            <div className="detail__co2-banner__content">
                                <div className="detail__co2-banner__content-items">
                                    {co2Ranks.map((item) => (
                                        <div key={item.rank} className="detail__co2-banner__content-item">
                                            <div className="detail__co2-banner__content-item__header">
                                                <div className="detail__co2-banner__content-item__rank">{item.rank}</div>
                                                <div className="detail__co2-banner__content-item__label">{item.label}</div>
                                            </div>
                                            <div className="detail__co2-banner__content-item__icon"><img src={item.svg} alt={item.label} /></div>
                                            <div className="detail__co2-banner__content-item__value">{item.value}</div>
                                        </div>
                                    ))}
                                </div>
                                <div className="detail__co2-banner__content-item total">
                                    <div className="detail__co2-banner__content-item__header">
                                        <div className="detail__co2-banner__content-item__rank">Celkem</div>
                                        <div className="detail__co2-banner__content-item__label">Dopady na<br />životní prostředí</div>
                                    </div>
                                    <div className="detail__co2-banner__content-item__icon"><img src={earthSVG} alt="Celkem" /></div>
                                    <div className="detail__co2-banner__content-item__value">
                                        <span>{this.state.info.ENVI_GWP}</span>
                                        <span className="unit">kg CO2 equiv/FU</span>
                                    </div>
                                </div>
                            </div>
                            <div className="detail__co2-banner__footnote">
                                <i>* Podrobnější informace o potenciálu globálního oteplování se dozvíte v <a href="/napoveda#gwp">Nápovědě.</a></i>
                            </div>
                        </div>
                    )}
                    
                    <div className="detail__contents">

                        {this.state.info && Object.keys(this.state.info).length > 0 &&
                            <div>
                                <h3>
                                    Podrobnosti
                                </h3>

                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Katalog:</th>
                                            <td>{this.state.info.cat}</td>
                                        </tr>
                                        {this.state.info.part && <tr>
                                            <th>Část:</th>
                                            <td>{this.state.info.part}</td>
                                        </tr>}
                                        <tr>
                                            <th>Soubor:</th>
                                            <td>
                                                {catObj.id &&
                                                    <button className="detail__select-link" onClick={() => { this.props.closeDialog(); this.props.selectCatalog(catObj); window.scrollTo(0, 0) }}>
                                                        {this.state.info.file}
                                                    </button>
                                                }
                                                {!catObj.id &&
                                                    <span>
                                                        {this.state.info.file}
                                                    </span>
                                                }
                                            </td>
                                        </tr>
                                        {this.state.info.note &&
                                            <tr>
                                                <th>Poznámky:</th>
                                                <td>
                                                    {notesProcessor(this.state.info.note)}
                                                </td>
                                            </tr>
                                        }



                                        {this.state.info.note2 &&
                                            <tr>
                                                <th>Poznámky:</th>
                                                <td>
                                                    <div class={classNames("Note_info", { hidden: !this.state.showMoreNote2 })}>
                                                        <span dangerouslySetInnerHTML={{ __html: this.state.info.note2 }} />

                                                        <a class="more_info" href="#" onClick={() => { this.setState({ showMoreNote2: true }) }}><span><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#1fad4b" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg></span></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                        {this.state.info.cenove_podminky &&
                                            <tr><th></th><td>
                                                <Chevlink href={this.state.info.cenove_podminky_url} target="_blank">
                                                    {this.state.info.cenove_podminky}
                                                </Chevlink>
                                            </td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }

                        <h3>
                            Ceny
                        </h3>
                    </div>


                    <div className="detail__prices">

                        {this.props.productType === "constructions" &&
                            <table>
                                <thead>
                                    <tr>
                                        <th>Cenová úroveň</th>
                                        <th>Materiál</th>
                                        <th>PZN</th>
                                        <th>Hrubé rozpětí</th>
                                        <th>Cena celkem</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.prices.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.type}</td>
                                                <td>{formatPrice(item.material)}</td>
                                                <td>{formatPrice(item.pzn)}</td>
                                                <td>{formatPrice(item.range)}</td>
                                                <td>{formatPrice(item.total)}</td>
                                            </tr>
                                        )
                                    })}
                                    {this.state.prices.length == 0 &&

                                        <tr>

                                            <td colspan="5" className="detail__prices__empty">Tato položka neobsahuje žádné cenové informace.</td>
                                        </tr>

                                    }
                                </tbody>
                            </table>
                        }
                        {this.props.productType === "materials" &&
                            <table>
                                <thead>
                                    <tr>
                                        <th>Cenová úroveň</th>
                                        <th>Nákupní cena</th>
                                        <th>Pořizovací přirážka</th>
                                        <th>Cena celkem</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.prices.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.type}</td>
                                                <td>{formatPrice(item.nakupni_cena)}</td>
                                                <td>{formatPrice(item.porizovaci_prirazka)}</td>
                                                <td>{formatPrice(item.total)}</td>
                                            </tr>
                                        )
                                    })}
                                    {this.state.prices.length == 0 &&

                                        <tr>

                                            <td colspan="4" className="detail__prices__empty">Tato položka neobsahuje žádné cenové informace.</td>
                                        </tr>

                                    }
                                </tbody>
                            </table>
                        }



                    </div>

                    <div className="detail__prices-mobile">
                        <div className="detail__prices-mobile__head">
                            <table>
                                <thead>
                                    <tr><th>Cenová úroveň</th></tr>
                                    <tr><th>Materiál</th></tr>
                                    <tr><th>PZN</th></tr>
                                    <tr><th>Hrubé rozpětí</th></tr>
                                    <tr><th>Cena celkem</th></tr>
                                </thead>
                            </table>
                        </div>
                        <div className="detail__prices-mobile__contents">
                            {this.state.prices.map((item, index) => {
                                return (
                                    <table key={index}>
                                        <tbody>
                                            <tr><td>{item.type}</td></tr>
                                            <tr><td>{formatPrice(item.material)}</td></tr>
                                            <tr><td>{formatPrice(item.pzn)}</td></tr>
                                            <tr><td>{formatPrice(item.range)}</td></tr>
                                            <tr><td><strong>{formatPrice(item.total)}</strong></td></tr>
                                        </tbody>
                                    </table>
                                )
                            })}
                        </div>


                    </div>



                    {this.state.variants &&


                        <div>
                            <h3>Varianty položky</h3>
                            <ProductListItems
                                items={this.state.variants ?? []}
                                onShowList={(e, item) => { e.currentTarget.blur(); this.props.showProduct(item) }}
                                emptyTdClassName="product-list__variant_empty"
                                elementOnEmpty="Tato položka nemá žádné varianty."
                            />
                        </div>
                    }
                </div>
            </Dialog>
        );
    }
}


export default ProductDialog;