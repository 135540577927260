import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class DropdownMenu extends React.Component {

    constructor(){
        
        super();

        this.state = {
            active: false,
        };

        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    };

    showDropdownMenu(event) {
        event.preventDefault();
        this.setState({ active: true }, () => {
        const root = document.getElementById("root");
        root.addEventListener('click', this.hideDropdownMenu);
    });
  }

    componentWillUnmount(){
        const root = document.getElementById("root");
        root.removeEventListener('click', this.hideDropdownMenu);
    }

    hideDropdownMenu() {
        this.setState({ active: false }, () => {
                const root = document.getElementById("root");
                root.removeEventListener('click', this.hideDropdownMenu);
        });
    }

    render() {

        let cls = "dropdown-menu";
        if(this.props.small){
            cls += " dropdown-menu--small";
        }
        if(this.state.active){
            cls += " dropdown-menu--active";
}
     if(this.props.anyActive){
            cls += " dropdown-menu--any-active";
        }

        return (
            <div className={cls}>
                <button
                    draggable={false}
                    className="dropdown-menu__button"
                    //onClick={this.showDropdownMenu}
                    >
                    {this.props.label && 
                        <span className="dropdown-menu__label">
                            {this.props.label}
                        </span>
                    }
                    <FontAwesomeIcon icon={!this.state.active ? "chevron-down" : "chevron-up"} className="dropdown-menu__icon" onClick={this.showDropdownMenu} />
                    
                </button>
                <div
                    className="dropdown-menu__menu"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                    }}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

class DropdownMenuDivider extends React.Component {
    render() {
        return (
            <div className="dropdown-menu__divider"></div>
        );
    }
}

class DropdownMenuItem extends React.Component {
    render() {
        let cls = "dropdown-menu__item";
        if(this.props.active){
            cls += " dropdown-menu__item--active";
        }
        let opacity ="";
        if(this.props.locked){
         opacity =" dropdown-menu__item__opacity";
        }
        return (
            <button className={cls + opacity} /* onClick={this.props.onClick} */ disabled /*={this.props.locked} */ >
                <span className="dropdown-menu__item__code">
                {this.props.kod}
                </span>
                <span className="dropdown-menu__item__label">
                {this.props.label}
                </span>
                
            
                {this.props.locked && <FontAwesomeIcon icon="lock" className="main-tabs__locked" />}
                {this.props.unlocked && <FontAwesomeIcon icon="unlock" className="main-tabs__unlocked" />}
                
            </button>
        );
    }
}



export {DropdownMenu};
export {DropdownMenuDivider};
export {DropdownMenuItem};