import React from 'react';
import { arrayMoveImmutable } from 'array-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DropdownMenu, DropdownMenuItem } from './DropdownMenu';



import Page from '../components/Page'
import 'es6-promise/auto'; // IE11
import axios from 'axios';



class MainTabs extends React.Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);

       /*  this.tabsRef = React.createRef();
        this.checkResponsivity = this.checkResponsivity.bind(this);
        this.state = {
            maxVisibleTab: -1
        } */
    }

    onChange(value, reset) {
        if(reset) {
        //window.history.replaceState({}, "Vyhledávání", '/index');
        }
        if (this.props.onChange) {
            this.props.onChange(value);
        }
        
    }



    componentDidMount() {
        // window.addEventListener("resize", this.checkResponsivity);
        // this.checkResponsivity();
    }

    componentDidUpdate() {
       // this.checkResponsivity();
    }

    /* checkResponsivity() {
        if (!this.tabsRef.current) return;

        let items = this.tabsRef.current.children;

        let maxWidth = this.tabsRef.current.offsetWidth - 70;
        let width = 0;
        let maxVisibleTab = 0;

        for (let i = 0; i < items.length; i++) {
            let tabItem = items[i];
            width += tabItem.offsetWidth;
            if (width < maxWidth) {
                maxVisibleTab = i;
            }
        }

        if (this.state.maxVisibleTab !== maxVisibleTab) {
            this.setState({ maxVisibleTab: maxVisibleTab });
        }

    }*/

    render() {

    let items = Object.assign([], this.props.items);

        return (

            <div className="main-tabs">
                <div className="main-tabs__tabs" ref={this.tabsRef}>
                    {items.map((item, index) => {

                        if (item.disabled) return null;

                        var cls = "main-tabs__tab";
                        if (this.props.currentTab && this.props.currentTab.id === item.id) {
                            cls += " main-tabs__tab--active";
                        }
                        
                                               
                        if(item.items){
                        
                            const anyActive = (this.props.currentTab.id === item.id) || item.items.some((x)=>x.id === this.props.currentTab.id);
                             
                            return (
                            <div className="main-tabs__dropdown" onClick={()=> this.onChange(item, 1)}>
                                <DropdownMenu small anyActive={anyActive} label={item.popis}  >
                                 {/*<DropdownMenuItem
                                            label={"Vše"}
                                            onClick={()=> this.onChange(item)}
                                            active={this.props.currentTab && this.props.currentTab.id === item.id}
                                        />*/}
                                    {item.items.map((item, index) => {
                                        
                                        const unlocked2 = item.ochrana && !item.zamknuto;
                                        const locked2 = item.ochrana && item.zamknuto;

                                        return <DropdownMenuItem
                                            key={index}
                                            label={item.popis}
                                            kod={item.kod}
                                            locked={locked2}
                                            unlocked={unlocked2}
                                            onClick={()=> this.onChange(item)}
                                            active={this.props.currentTab && this.props.currentTab.id === item.id}
                                        />
                                    }
                                    )}
                                </DropdownMenu>
                            </div>
                            );
                         }

                        const unlocked = item.ochrana && !item.zamknuto;
                        const locked = item.ochrana && item.zamknuto;
                        
                        return (
                            <button className={cls} disabled={locked} onClick={() => { if(!locked) { this.onChange(item, 1); } }} key={index}>
                                {item.popis}
                                {locked && <FontAwesomeIcon icon="lock" className="main-tabs__locked" />}
                                {unlocked && <FontAwesomeIcon icon="unlock" className="main-tabs__unlocked" />}
                            </button>
                        );

                    })}
                </div>

             
            </div>

        )

    }

}



export default MainTabs;