import PropTypes from 'prop-types';
import React from 'react';
import Spinner from './Spinner';

/**
 * Loading overlay with spinner and message.  
 * Accessible via `EditorContext.editor.loadingStart("Something");` and `EditorContext.editor.loadingEnd();`
 * @class
 */
function Loader(props) {

    let cls = 'loader';
    if (props.active) {
        cls += ' loader--active';
    }

    document.documentElement.classList.toggle('loader-active', !!props.active);

    return (
        <div className={cls}>
            <div className="loader__box">
                <Spinner />
            </div>
        </div>
    );
}

Loader.propTypes = {
    /** Whether to show it */
    active: PropTypes.bool,
};


export default Loader;