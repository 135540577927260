import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';


function AppBarItem(props) {

    const [dropdownActive, setDropdownActive] = useState(false);

    let cls = "app-bar__item";
    if (props.active) {
        cls += " app-bar__item--active";
    }

    let buttonProps = {};
    let ButtonType = "span";
    if (props.onClick) {
        ButtonType = "button";
        buttonProps.onClick = props.onClick;
    }
    if (props.linkTo) {
        ButtonType = "a";
        buttonProps.href = props.linkTo;
        buttonProps.target = props.target;
    }
    if (props.dropdownMenuItems) {
        ButtonType = "button";
        buttonProps.onClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setDropdownActive(!dropdownActive);
        }
        cls += " app-bar__item--dropdown";
        if (dropdownActive) {
            cls += " app-bar__item--dropdown-active";
        }

    }

    useEffect(() => {
        window.addEventListener('click', () => setDropdownActive(false));
        return () => {
            window.removeEventListener('click', () => setDropdownActive(false));
        }
    }, []);



    return (
        <li>
            <ButtonType className={cls} {...buttonProps}>
                <FontAwesomeIcon className="app-bar__icon" icon={props.icon} />
                <span className="app-bar__item__title">
                    {props.title}
                </span>
            </ButtonType>
            {props.dropdownMenuItems &&
                <ul className="app-bar__dropdown">
                    {props.dropdownMenuItems.map((item) => (
                        <li key={item.id}>
                            <a href={item.linkTo} target={item.target}>{item.label}</a>
                        </li>
                    ))}
                </ul>
            }
        </li>
    )
}




function AppBar(props) {
    return (
        <div className="app-bar">
            <div className="app-bar__logo" onClick={props.goToIndex}>
                ÚRS - Cenová soustava
            </div>
            <ul className="app-bar__menu">
                {props.children}
            </ul>
        </div>
    );
}

export { AppBar };
export { AppBarItem };

