import React from 'react';




class MainBar extends React.Component {
    render() {
        return (
            <div className="main-bar">
                {this.props.top &&
                    <div className="main-bar__top">
                        {this.props.top}
                    </div>
                }
                <div className="main-bar__middle">
                    <div className="main-bar__left">
                        {this.props.left}
                    </div>
                    <div className="main-bar__right">
                        {this.props.right}
                    </div>
                </div>
                {this.props.bottom &&
                    <div className="main-bar__bottom">
                        {this.props.bottom}
                    </div>
                }
            </div>
        )
    }
}

export default MainBar;
