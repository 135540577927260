import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Animated spinner component
 * @class
 */
function Spinner(props) {
    return (
        <div
            className={classNames({
                'spinner': true,
                'spinner--fast': props.fast,
                'spinner--linear': props.linear,
                'spinner--small': props.small,
            })}
        />
    );
}

Spinner.propTypes = {
    /** Fast spinning */
    fast: PropTypes.bool,
    /** Small spinner */
    small: PropTypes.bool,
    /** Linear spinning */
    linear: PropTypes.bool
};

export default Spinner;