import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ListButton extends React.Component {
    render() {
        return (
            <button className="list-button" onClick={this.props.onClick}>
                <FontAwesomeIcon icon="list-ul" className="list-button__icon" />
                <span className="list-button__label">
                    Vybrat ze seznamu
                </span>
            </button>
        );
    }
}

export default ListButton;
