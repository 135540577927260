import React, { useState } from 'react';

function Expiration(props) {
    const [closed, setClosed] = useState(false);
    let cls = "expiration";
    if (closed) {
        cls += " expiration--closed";
    }
    return (
        <div
            className={cls}
        >
            <button className="expiration__title" onClick={() => setClosed(false)}>
                Výstraha!
            </button>
            <button className="expiration__close" onClick={() => setClosed(true)}>
                X
            </button>
            <div className="expiration__content">
                {props.expiration.text_pred &&
                    <div className="expiration__header" dangerouslySetInnerHTML={{__html: props.expiration.text_pred}} />
                }
                {props.expiration.balicky &&
                    <div className="expiration__packages">
                        <ul>
                            {props.expiration.balicky.map((item, i) => (
                                <li key={i}><span dangerouslySetInnerHTML={{ __html: item }}></span></li>
                            ))}
                        </ul>
                    </div>
                }
                {props.expiration.text_za &&
                    <div className="expiration__footer" dangerouslySetInnerHTML={{__html: props.expiration.text_za}} />
                }
            </div>
        </div>
    );

}


export default Expiration;