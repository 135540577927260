import React from 'react';
import Chevlink from './Chevlink';



class Banner extends React.Component {

    render() {
  
        let c = "banner";
        if(this.props.color){
            c += " banner--" + this.props.color;
        }
   
        return (
            <div
                className={c}
            >
                {this.props.img && 
                    <div className="banner__img">
                        <img src={this.props.img} alt={this.props.name} />
                    </div>
                }
                <div className="banner__content">
                    {this.props.name &&
                        <div className="banner__name">
                            {this.props.name}
                        </div>  
                    }

                    {this.props.text && 
                        <div className="banner__text" dangerouslySetInnerHTML={{__html: this.props.text}}></div>
                    }

                    {this.props.url && this.props.url_text && 
                        <div className="banner__link">
                            <Chevlink href={this.props.url} target="_blank">{this.props.url_text}</Chevlink>
                        </div>
                    }
                </div>
                <button className="banner__close" onClick={this.props.onCloseClick}></button>
            </div>
        );
    }
}


class Banners extends React.Component {
    render(){
        return (
            <div className="banners">
                {this.props.banners && this.props.banners.map((item)=>(
                    <Banner
                        id={item.id}
                        key={item.key || item.id}
                        color={item.color}
                        name={item.name}
                        text={item.text}
                        img={this.props.noImage ? null : item.img}
                        url={item.url}
                        url_text={item.url_text}
                        onCloseClick={()=>this.props.closeBanner(item.id)}
                    />
                ))}
                {this.props.children}
            </div>
        )
    }
}

export {Banner};
export {Banners};
