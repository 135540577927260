import React from 'react';
import Page from '../components/Page'
import 'es6-promise/auto'; // IE11
import axios from 'axios';

class PageLoader extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            page:null
        }
        this.onContentClick = this.onContentClick.bind(this);
    }

    componentDidMount(){
        this.reloadPage();
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentPage !== prevProps.currentPage) {
            this.reloadPage();
        }
        
        // if is scrolled then do not scroll to anchor
        if(window.scrollY > 0){
            return;
        }
        
        const anchorHash = window.location.hash;
        if (anchorHash && anchorHash.length > 0) {
            const anchorName = anchorHash.substr(1);
            const matingAnchors = document.getElementsByName(anchorName);
            if (matingAnchors.length > 0) {
                matingAnchors[0].scrollIntoView();
            }
        }
    }

    reloadPage(){
        
        let name = this.props.currentPage;
        let _this = this;

        if(!name) {
            this.setState({
                page: null
            });
            return;
        }
        
        axios.get('/api/page/' + name)
        .then(function (response) {
            if(!_this.props.errorHandler.check(response)) return;
            let page = response.data;
            if(!page || (!page.title && !page.content)){
                page = {
                    name: name,
                    content: "<a href=\"index\">Přejít na úvod</a>",
                    title: "Stránka nenalezena"
                }
            }else{
                page.name = name;
                // when not same name in URL (eg.click back), push to history
                if(name !== window.location.pathname.substr(1)){
                    window.history.pushState(page, page.title, '/' + name);
                }
            }
            _this.setState({
                page: page
            });
        })
        .catch(function (error) {
            console.log(error);
            _this.setState({
                page: null
            });
        });
    }

    onContentClick(e){
        const targetLink = e.target.closest('a');
        if(!targetLink){
            return;
        }
        let pageName = targetLink.getAttribute("href");

        var rgx = /^[0-9a-zA-Z-]+$/;
        if(rgx.test(pageName))
        {
            e.preventDefault();
            this.props.loadPage(pageName)
            return false;
        }
        
        return true;
    }

    
    render(){
        if(!this.state.page){
            return null;
        }

        return (
            <Page
                center={this.state.page.align === "center"}
                title={this.state.page.title}
            >
                <div onClick={this.onContentClick} dangerouslySetInnerHTML={{__html: this.state.page.content}} />
            </Page>
        )
    }
}

export default PageLoader;