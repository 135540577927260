import axios from 'axios';
import 'es6-promise/auto'; // IE11
import React from 'react';
import Catalog from '../components/Catalog';
import Dialog from '../components/Dialog';
import Spinner from '../components/Spinner';
import { Tab, Tabs } from '../components/Tabs';



class ListDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: "constructions",
            katalogMaterialy: null,
            katalogKonstrukce: null,
        }
    }

    componentDidMount() {
        this.loadCatalogs();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.komplet && !this.props.komplet) || (!prevProps.komplet && this.props.komplet) || (prevProps.komplet && prevProps.komplet.id !== this.props.komplet.id)) {
            this.loadCatalogs();
        }
        this.state.currentTab = this.props.productType;
    }

    loadCatalogs() {
        let _this = this;
        this.setState({ katalogMaterialy: null, katalogKonstrukce: null }, () => {

            const params = {
                params: {
                    komplet: this.props.komplet ? this.props.komplet.id : null
                }
            };

            axios.get('/api/material-katalog', params)
                .then(function (response) {
                    if (!_this.props.errorHandler.check(response)) return;
                    _this.setState({ katalogMaterialy: response.data });
                })
                .catch(function (error) {
                    console.log(error);
                });
            axios.get('/api/konstrukce-katalog', params)
                .then(function (response) {
                    if (!_this.props.errorHandler.check(response)) return;
                    _this.setState({ katalogKonstrukce: response.data });
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }


    render() {
        return (
            <React.Fragment>
                <Dialog
                    active={this.props.active}
                    closeDialog={this.props.closeDialog}
                    label="Výběr ze seznamů"
                    actions={[
                        {
                            label: "Zavřít",
                            onClick: this.props.closeDialog
                        }
                    ]}
                >
                    <Tabs
                        border
                        currentTab={this.state.currentTab}
                        onChange={(val) => this.setState({ currentTab: val })}
                    >
                        <Tab id="constructions" label="Konstrukce">
                            {this.state.katalogKonstrukce &&
                                <Catalog
                                    onItemClick={(catalog) => this.props.selectCatalog(catalog, "constructions")}
                                    activeCatalog={this.props.activeCatalog}
                                    catalog={this.state.katalogKonstrukce}
                                    komplet={this.props.komplet}
                                />
                            }
                            {!this.state.katalogKonstrukce &&
                                <Spinner linear />
                            }
                        </Tab>
                        <Tab id="materials" label="Materiály"  disabled={this.props.komplet.id < -1 } >
                            {this.state.katalogMaterialy &&
                                <Catalog
                                    onItemClick={(catalog) => this.props.selectCatalog(catalog, "materials")}
                                    activeCatalog={this.props.activeCatalog}
                                    catalog={this.state.katalogMaterialy}
                                    komplet={this.props.komplet}
                                />
                            }
                            {!this.state.katalogMaterialy &&
                                <Spinner linear />
                            }
                        </Tab>
                    </Tabs>
                </Dialog>
            </React.Fragment>
        );
    }
}


export default ListDialog;