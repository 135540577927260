import React from 'react';

class SearchStatsCatalog extends React.Component {

    render() {
        if(!this.props.catalog) return null;

        var txt = "Vybrán katalog";
        if(this.props.productType && this.props.productType === "constructions"){
            txt = "Vybrán soubor konstrukcí";
        }
        if(this.props.productType && this.props.productType === "materials"){
            txt = "Vybrán soubor materiálů";
        }
 
        return (
            <div className="search-results">
                <p>
                    {txt}: <button className="search-results__link" onClick={this.props.onCatalogClick}>{this.props.catalog.code} {this.props.catalog.label.trim()}</button>.
                </p>
            </div>
        );

    }
}

export default SearchStatsCatalog;
