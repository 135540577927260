import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import Collapsible from 'react-collapsible';
import scrollIntoView from 'scroll-into-view-if-needed';
import * as Collapsible from '@radix-ui/react-collapsible';



class Catalog extends React.Component {

    constructor(props) {
        super(props);
        this.onItemClick = this.onItemClick.bind(this);
    }

    onItemClick(item) {
        if (this.props.onItemClick) this.props.onItemClick(item);
    }

    renderItems(items, indent, disabled, activeParents) {

        if (!items) {
            return (
                <div className="catalog__empty">
                    Žádné katalogy.
                </div>
            );
        }

        let cls = "catalog__items";
        if (disabled) {
            cls += " catalog__items--disabled";
        }

        return (
            <ul className={cls} data-indent={indent}>
                {items.map((item, index) => {

                    if (item.items && item.items.length > 0) {

                        let open = false;
                        if (activeParents) {
                            open = activeParents[indent] === item.id;
                        }

                        return (
                            <li className="catalog__item" key={index}>
                                
                                  <Collapsible.Root
                                    className="catalog__collapsible"
                                    defaultOpen={open}
                                    >
                                    <Collapsible.Trigger>
                                    <div className="catalog__name catalog__name--collapse">
                                            <span className="catalog__pm"></span>
                                            <span className="catalog__code">
                                                {item.code}
                                            </span>
                                            <span className="catalog__label">
                                                {item.label}
                                            </span>
                                        </div>
                                    </Collapsible.Trigger>
                                    <Collapsible.Content>
                                    {this.renderItems(item.items, indent + 1, disabled, activeParents)}
                                    </Collapsible.Content>
                                  </Collapsible.Root>

                                
                            </li>
                        )

                    } else {
                        const active = (this.props.activeCatalog && (this.props.activeCatalog.id === item.id));
                        let id = null;

                        if (active) {
                            id = "item-" + Object.keys(activeParents).map(k => activeParents[k]).join("-");
                            setTimeout(() => {
                                let node = document.getElementById(id);
                                node && scrollIntoView(node, { behavior: 'auto', scrollMode: 'if-needed' })
                            }, 50);
                        }
                        return (
                            <li className="catalog__item" key={index} id={id}>
                                <div className={"catalog__name catalog__name--link" + (active ? " catalog__name--active" : "")} onClick={() => this.onItemClick(item)}>
                                    <FontAwesomeIcon className="catalog__chevron" icon="chevron-right" />
                                    <span className="catalog__code">
                                        {item.code}
                                    </span>
                                    <span active={active} className="catalog__label">
                                        {item.label}
                                    </span>
                                </div>
                            </li>
                        )
                    }
                })}
            </ul>
        );
    }

    generateParents(items, idToFind, indent) {
        let parents = {};
        if (items) {
            items.forEach(item => {
                if (item.id === idToFind) {
                    parents[indent] = item.id;
                } else if (item.items) {
                    let res = this.generateParents(item.items, idToFind, indent + 1);
                    if (Object.keys(res).length > 0) {
                        parents = Object.assign({}, parents, res);
                        parents[indent] = item.id;
                    }
                }
            });
        }
        return parents;
    }


    render() {

        let head = "Katalogy";
        let headDisabled = "Nevybrané katalogy";

        let activeParents = null;


        if (this.props.komplet) {
            head = <span>Katalogy pro „{this.props.komplet.popis}“ </span>;
            headDisabled = <span>Katalogy <strong>nepatřící</strong> do kompletu „{this.props.komplet.popis}“ </span>;
        }

        if (this.props.activeCatalog) {
            activeParents = this.generateParents(this.props.catalog.items, this.props.activeCatalog.id, 0);
        } else if (this.props.catalog.items && this.props.catalog.items.length === 1) {
            activeParents = {
                0: this.props.catalog.items[0].id
            }
        }

        return (
            <div className="catalog">
                <div className="catalog__section">
                    <h3 className="catalog__head">
                        {head}
                    </h3>
                    {this.renderItems(this.props.catalog.items, 0, false, activeParents)}
                </div>
                {this.props.catalog.disabledItems && this.props.catalog.disabledItems.length > 0 &&
                    <div className="catalog__section">
                        <h3 className="catalog__head catalog__head--disabled">
                            {headDisabled}
                        </h3>
                        {this.renderItems(this.props.catalog.disabledItems, 0, true, activeParents)}
                    </div>
                }
            </div>
        )
    }
}

export default Catalog;

