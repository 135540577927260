import React from 'react';


class Page extends React.Component{
    render(){

        let cls = "page";
        if(this.props.center){
            cls += " page--center";
        }
        if(!this.props.title){
            cls += " page--no-title";
        }

        return (
            <div className={cls}>
                {this.props.title &&
                    <div className="page__title">
                        <h1>
                            {this.props.title}
                        </h1>
                    </div>
                }
                <div className="page__contents">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default Page;