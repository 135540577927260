import classNames from 'classnames';
import React, { useState } from 'react';

function ItemCO2LeafTooltip({ value, show }) {
    return (
        <div class={classNames("co2-leaf__tooltip", { visible: show })}>
            <span class="co2-leaf__tooltip__value">
                {value}
            </span>
            <span class="co2-leaf__tooltip__unit">kg CO2 equiv/FU</span>
        </div>
    );
}

function ItemCO2LeafInner({ item, ...divProps }) {
    const [hover, setHover] = useState(false);
    return (
        <div class="co2-leaf">
            <div
                class="co2-leaf__target"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                {...divProps}
            >
                <ItemCO2LeafTooltip
                    show={hover}
                    value={item.envi_gwp}
                />
            </div>
        </div>
    );
}

export default function ItemCO2Leaf({ item, ...divProps }) {
    if (item.envi_x !== "1") {
        return null;
    }
    return <ItemCO2LeafInner item={item} {...divProps} />
}
