import React from 'react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function HeaderItem(props){

    let Tag = "span";
    let cls = "header__item";
    if(props.onClick){
        Tag = "button";
    }
    if(props.responsive){
        cls += " header__item--responsive";
    }
    if(props.warning){
        cls += " header__item--warning";
    }
    return (
        <li>
            <Tag className={cls} onClick={props.onClick}>
                <FontAwesomeIcon icon={props.icon} />
                <span className="header__item__title">
                    {props.title}
                </span>
            </Tag>
        </li>
    )
}



class Header extends React.Component{
    render(){
        return (
            <div className="header">
                <div className="header__inside">
                    <ul className="header__menu">
                        
                       {this.props.children}

                    </ul>
                </div>
            </div>
        )
    }
}

export {Header};
export {HeaderItem};