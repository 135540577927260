
class ErrorHandler {

    constructor(app, urls) {
        this.app = app;
        this.urls = urls;
    }

    check(response) {
        var data = response.data;
        if (data.error && data.error.id) {
            if (data.error.userLogout) {
                this.app.setState(this.app.createInitialState());
            }
            if (data.error.redirectPage) {
                this.app.setState({ currentPage: data.error.redirectPage });
            }
            return false;
        }

        return true;
    }



}

export default ErrorHandler;