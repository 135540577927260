
function formatPrice(src, hideCzk){

    if(!src) return "";
    
    let dst = src.toString();
    /*
    let parts = dst.split(".", 2);

    let firstPart = "";
    let len = parts[0].length;

    for(let i = 0; i < len; i++){
        firstPart = parts[0][len - i - 1] + firstPart;
        if((i+1) % 3 === 0){
            firstPart = " " + firstPart;
        }
    }


    if(parts.length > 1){
        dst = firstPart + "," + parts[1];
    }else{
        dst = firstPart;
    }*/


    if(!hideCzk){
        dst = dst + " Kč";
    }

    return dst;
}

export default formatPrice;