import React from 'react';

class Tab extends React.Component {


    render() {
        return (
            <li className={this.props.hidden ? "tabs_hide" : null }>//je to na ��dku 43!!!
                {this.props.label}
            </li>
        )
    }
}


class Tabs extends React.Component {


    render() {
        
        let cls = "tabs";
        if(this.props.center){
            cls += " tabs--center";
        }
        if(this.props.productTabs){
            cls += " tabs--product-tabs";
        }
        if(this.props.border){
            cls += " tabs--border";
        }

        return (
            <div className={cls}>
                <ul className="tabs__nav">
                    {this.props.children.map((item, index) => {
                        if(item.props.disabled) return null;
                            
                        var cls = "tabs__button";
                        if(this.props.currentTab === item.props.id){
                            cls += " tabs__button--active";
                        }

                        return(
                            <li key={index}>
                                <button className={cls} onClick={() => { if(item.props.onClick){ item.props.onClick(); } this.props.onChange(item.props.id);  }}>
                                    {item.props.label}
                                </button>
                            </li>
                        );
                    })}
                </ul>
                {this.props.children.map((item, index) => {
                    if(!item.props.children){
                        return null;
                    }

                    var cls = "tabs__content";
                    if(this.props.currentTab === item.props.id){
                        cls += " tabs__content--active";
                    }

                    return(
                        <div key={index} className={cls}>
                            {item.props.children}
                        </div>
                    )
                })}
            </div>
        );
    }
}

export {Tab};
export {Tabs};
