import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import formatPrice from '../utils/formatPrice';
import Squares from './Squares';
import ItemCO2Leaf from './ItemCO2Leaf';

export default function ProductListItems({
    items,
    onShowList,
    elementOnEmpty,
    elementAfterTable=null,
    emptyTdClassName="product-list__error"
}) {
    return (
        <div className="product-list">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Kód</th>
                            <th>Popis</th>
                            <th className="product-list__unit-th">mj</th>
                            <th className="product-list__price-th">Cena</th>
                            <th className="product-list__action-th">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td data-label="Legenda" className="product-list__legend"><Squares {...item} /></td>
                                    <td data-label="Kód" className="product-list__id">{item.id}</td>
                                    <td data-label="Popis"><span dangerouslySetInnerHTML={{ __html: item.title }}></span></td>
                                    <td data-label="Mj" className="product-list__unit">
                                        <span dangerouslySetInnerHTML={{ __html: item.unit }}></span>
                                    </td>
                                    <td data-label="Cena" className="product-list__price"><strong>{formatPrice(item.price, true)}</strong> Kč</td>
                                    <td className="product-list__action">
                                        <div class="product-list__action__group">
                                            <ItemCO2Leaf item={item} onClick={(e) => onShowList(e, item)} />
                                            <button className="product-list__show" onClick={(e) => onShowList(e, item)}>
                                                <FontAwesomeIcon className="product-list__show__icon" icon="search" />
                                                <span className="product-list__show__label">
                                                    zobrazit detail
                                                </span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        {items.length === 0 &&
                            <tr>
                                <td colSpan={6} className={emptyTdClassName}>
                                    {elementOnEmpty}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {elementAfterTable}
            </div>
    );
}