import React from 'react';


class Pager extends React.Component{

    constructor(props){
        super(props);
        this.goToPage = this.goToPage.bind(this);
    }

    goToPage(num){
        this.props.goToPage(num);
    }


    render(){

        let space = <span className="pager__space">...</span>;
        let buttons = [];
        let current = this.props.currentPage;
        let spaceActive = false;
        let pages = this.props.pages;

        for(let i = 0; i < pages; i++){
            let cls = null;
            if(current === i){
                cls = "active";
            }

            if(Math.abs(i - current) <= 2 || i === 0 || i === pages - 1){
                buttons.push(<button className={cls} onClick={()=>this.goToPage(i)}>{i+1}</button>);
                spaceActive = false;
            }else{
                if(!spaceActive){
                    buttons.push(space);
                    spaceActive = true;
                }
            }
        }

        if(this.props.count === 999){
            buttons.push(space);
        }


        
      
        return (
             <div className="pager">
                <div className="pager__right">
                    <ul className="pager__pages">
                        {buttons.map((item, index)=>{
                            return (
                                <li key={index}>{item}</li>
                            )
                        })}
                    </ul>
                </div>
                <div className="pager__left">
                    <select
                        className="pager__on-page"
                        value={this.props.perPage}
                        onChange={(e) => this.props.onPerPageChange(e.target.value)}
                    >
                        {
                            this.props.perPageOptions.map((item) => {
                                return (
                                    <option key={item} value={item}>{item}</option>
                                )
                            })
                        }
                    </select>
                    položek na stránku
                </div>
            </div>
        )
    }
}

export default Pager;
