import React from 'react';
function Squares(props) {
        return (
            <div
                className="squares"
            >
                {props.variant && 
                    <span className={`squares__sq squares__sq--variant-${props.variant}`} title={`${props.variant_name}`} />
                }
                 {props.druh_polozky && 
                    <span className={`squares__sq squares__sq--druh_polozky-${props.druh_polozky}`} title={`${props.druh_polozky_name}`} />
                }
            </div>
        );
}
export default Squares;
