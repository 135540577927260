import React from 'react';



class Button extends React.Component {

    render() {
  
        let c = "button";
   
        if(this.props.primary){
            c += " button--primary";
        }
   

        return (
            <button
                className={c}
                onClick={this.props.onClick}
                disabled={this.props.disabled}
            >
                {this.props.label}
            </button>
        );
    }
}
export default Button;
