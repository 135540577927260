import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class Chevlink extends React.Component {

    renderButton(){
        return(
            <button className="chevlink" onClick={this.props.onClick}>
                <FontAwesomeIcon className="chevlink__chevron" icon="chevron-right" />
                <span className="chevlink__label">
                    {this.props.children}
                </span>
            </button>
        )
    }
    
    renderLink(){
        return(
            <a className="chevlink" href={this.props.href} target={this.props.target} rel="noopener noreferrer">
                <FontAwesomeIcon className="chevlink__chevron" icon="chevron-right" />
                <span className="chevlink__label">
                    {this.props.children}
                </span>
            </a>
        )
    }

    render() {
        if(this.props.href){
            return this.renderLink();
        }
        return this.renderButton();
    }
}

export default Chevlink;

