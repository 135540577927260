import React from 'react';
import ProductDialog from '../dialogs/ProductDialog';
import ProductListItems from './ProductListItems';

class ProductList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentProduct: null,
            productDialogActive: false
        }

        this.showProduct = this.showProduct.bind(this);
        this.closeProduct = this.closeProduct.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.items && this.props.items.length === 1 && this.props.openSingleResult && !this.state.productDialogActive) {
            this.setState({
                currentProduct: this.props.items[0],
                productDialogActive: true
            });
        }
    }

    showProduct(product) {
        this.setState({
            currentProduct: product,
            productDialogActive: true
        });
    }

    closeProduct() {
        this.props.onProductClose();
        this.setState({
            productDialogActive: false
        });
    }

    render() {
        return (
            <ProductListItems
                items={this.props.items ?? []}
                onShowList={(e, item) => { e.currentTarget.blur(); this.showProduct(item) }}
                elementOnEmpty={(
                    <>
                        {!this.props.invalidSearch && this.props.searchQuery != "null" &&
                            <div className="product-list__empty">
                                Nenalezeny žádné položky.
                            </div>
                        }
                        {this.props.invalidSearch &&
                            <div className="product-list__empty">
                                Zadejte prosím dotaz k vyhledání nebo vyberte katalog ze seznamů.
                            </div>
                        }
                        {this.props.searchQuery == "null" &&
                            <div className="product-list__empty">
                                Zadejte prosím dotaz k vyhledání nebo vyberte katalog ze seznamů.
                            </div>
                        }
                    </>
                )}
                elementAfterTable={(
                    <ProductDialog
                        selectCatalog={this.props.selectCatalog}
                        product={this.state.currentProduct}
                        active={this.state.productDialogActive}
                        productType={this.props.productType}
                        closeDialog={this.closeProduct}
                        errorHandler={this.props.errorHandler}
                        banners={this.props.banners}
                        closeBanner={this.props.closeBanner}
                        showProduct={this.showProduct}
                    />
                )}
            />
        )
    }
}

export default ProductList;
