import React from 'react';
import 'es6-promise/auto'; // IE11
import 'polyfill-array-includes';
import "ie-array-find-polyfill";
import axios from 'axios';
import ProductList from './ProductList';
import Filter from './Filter';
import Pager from './Pager';
import removeValue from 'remove-value';
import config from '../../config';

const FILTER_SECTIONS = [
    {
        source: 'katalogy',
        name: 'katalogy',
        title: 'Katalogy'
    },
    {
        source: 'merne_jednotky',
        name: 'merneJednotky',
        title: 'Měrné jednotky'
    },
    {
        source: 'prace',
        name: 'prace',
        title: 'Práce'
    },
    {
        source: 'varianty',
        name: 'varianty',
        title: 'Typ varianty'
    },
    {
        source: 'druh_polozky',
        name: 'druh_polozky',
        title: 'Druh položky'
    },
    {
        source: 'suroviny',
        name: 'suroviny',
        title: 'Suroviny'
    }
    
];


class Products extends React.Component {

    constructor(props) {
        super(props);

        this.perPageOptions = [
            10, 25, 50
        ];

        this.state = {
            changing: false,
            currentPage: 0,
            perPage: 25,
            pages: 0,
            count: 0,
            items: [],
            filterSections: [],
            enabledFilterItems: {},
            keep: false
        }

        this.makeSearch = this.makeSearch.bind(this);
        this.filterItemClick = this.filterItemClick.bind(this);
        this.clearFilterSection = this.clearFilterSection.bind(this);
    }

    clearFilterSection(name) {
        this.setState(
            (prevState) => ({
                ...prevState,
                enabledFilterItems: {
                    [name]: []
                }
            }),
            () => { this.makeSearch(false); }
        );
    }

    makeFilterSection(label, name, items) {
        var enabled = this.state.enabledFilterItems;
        if (typeof enabled[name] === "undefined") {
            enabled[name] = [];
        } else {
            if (items) {
                items.forEach(item => {
                    const e = enabled[name].includes(item.id);
                    if (e) {
                        item.enabled = true;
                    }
                    if(item.items){
                   item.items.forEach(subitem => {
                        if (e && enabled[name].includes(subitem.id)) {
                            subitem.enabled = true;
                        }else{
                            subitem.enabled = false;
                        }
                    });
                    }
                });
            }
        }
        return {
            label: label,
            name: name,
            items: items ? items : []
        }
    }

    filterItemClick(name, id) {
        var enabled = this.state.enabledFilterItems;
        let filterSection = this.state.filterSections.find(x => x.name === name);
        if (filterSection) {
            let item = filterSection.items.find(x => x.id === id);
            if(!item){
                filterSection.items.forEach((item2)=>{
                    if(item2.items){
                        let tmp =  item2.items.find(x => x.id === id);
                        if(tmp){
                            item = tmp;
                        }
                    }
                });
            }else{
                if(item.enabled){
                    if(item.items){
                        item.items.forEach((item3)=>{
                            item3.enabled = false;
                            removeValue(enabled[name], item3.id);
                        });         
                    }
                }
            }
            if (item) {
                item.enabled = !item.enabled;
                if (item.enabled) {
                    if (!enabled[name].includes(id)) {
                        enabled[name].push(id);
                    }
                } else {
                    if (enabled[name].includes(id)) {
                        removeValue(enabled[name], id);
                    }
                }
            }
            // clear subenabled if more than 1 enabled
            if(filterSection.items.filter((x)=>x.enabled).length > 1){
               filterSection.items.forEach((item2)=>{
                    if(item2.items){
                        item2.items.forEach((item3)=>{
                            item3.enabled = false;
                             removeValue(enabled[name], item3.id);
                        });         
                    }
                });
            }
            
            this.setState({ filterSections: this.state.filterSections, enabledFilterItems: enabled, currentPage: 0 }, () => {
                this.makeSearch(false);
            });
        }
    }

    makeSearch(resetEnabledFilterItems, reallyReset = false, scrollToTop = false) {

        this.props.whenSearchingStarts();

        if (!this.props.searchQuery && !this.props.catalog) {
            this.setState({
                items: [],
                currentPage: 0,
                pages: 0,
            });
            return;
        }

        let _this = this;

        this.setState({
            changing: true,
            enabledFilterItems: ((resetEnabledFilterItems && !this.state.keep) || reallyReset) ? {} : this.state.enabledFilterItems
        }, () => {

            let showOnlyItems = {};
            for (let name in this.state.enabledFilterItems) {
                let items = this.state.enabledFilterItems[name];
                if (items.length > 0) {
                    showOnlyItems[name] = [];
                    let filterSection = this.state.filterSections.find(x => x.name === name);
                    if (filterSection) {
                        filterSection.items.forEach(item => {
                            if (item.enabled) {
                                showOnlyItems[name].push(item.id);
                            }
                            if(item.items){
                            item.items.forEach(item2 => {
                                if (item2.enabled) {
                                    showOnlyItems[name].push(item2.id);
                                }
                            });
                            }
                        })
                    }
                }
            }

            let searchParams = {
                q: this.props.searchQuery,
                keep: this.state.keep,
                katalog: this.props.catalog ? this.props.catalog.id : null,
                perPage: this.state.perPage,
                page: this.state.currentPage,
                komplet: (this.props.komplet.id !== 0) ? this.props.komplet.id : null,
                showOnly: Object.keys(showOnlyItems).length > 0 ? showOnlyItems : null
            }
            
            window.history.replaceState({ search: searchParams }, "Vyhledávání", '/' + config.searchPageName + '/' +  this.props.productType + '/' + this.props.searchQuery);

            // productType eg. "construction" , "materials"
            axios.get('/api/' + this.props.productType, {
                params: searchParams
            })
                .then(function (response) {

                    if (!_this.props.errorHandler.check(response)) return;

                    let filterSections = [];
                    FILTER_SECTIONS.forEach((fs) => {
                        if (response.data[fs.source]) {
                            filterSections.push(_this.makeFilterSection(fs.title, fs.name, response.data[fs.source]));
                        }
                    });
                    

                    _this.setState({
                        items: (response.data.items) ? response.data.items : [],
                        pages: response.data.pages,
                        count: response.data.count,
                        perPage: response.data.perPage,
                        currentPage: response.data.currentPage,
                        filterSections: filterSections
                    })
                    
                    if (_this.props.setStats) {
                        _this.props.setStats({
                            shown: (response.data.items) ? response.data.items.length : 0,
                            total: response.data.count,
                            where: null
                        })
                    }

                    setTimeout(() => {
                        _this.setState({ changing: false });

                        if(scrollToTop){
                            const el = document.querySelector('.product-list');
                            if(el && el.scrollIntoView){
                                el.scrollIntoView({ behavior: 'smooth', block: 'start'});
                            }
                        }

                    }, 100);
                })
                .catch(function (error) {
                    console.log(error);
                    if (_this.props.setStats) {
                        _this.props.setStats({
                            shown: 0,
                            total: 0,
                            where: "ERROR"
                        })
                    }
                    setTimeout(() => {
                        _this.setState({ changing: false });
                    }, 100);
                });

        })
    }

    componentDidMount() {
        this.makeSearch();
        // /vyhledavani/konstrukce/210192645
        const tabName = window.location.pathname.split('/')[2] || '';
        const searchString = window.location.pathname.split('/')[3] || '';
        if(searchString && tabName){
            this.setState({
                openSingleResult: true
            });
            this.props.onInit(tabName, searchString);
        }
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.searchQuery !== prevProps.searchQuery ||
            this.props.productType !== prevProps.productType ||
            this.props.komplet !== prevProps.komplet ||
            this.props.catalog !== prevProps.catalog
        ) {
            const dontKeep = (this.props.productType !== prevProps.productType) || ((this.props.komplet?.id !== prevProps.komplet?.id) && this.props.komplet?.id < -1);
            if(dontKeep){
                this.setState({keep: false});
            }
                
            if (this.state.currentPage === 0 || prevState.currentPage !== this.state.currentPage) {
                this.makeSearch(true, dontKeep);
            } else {
                this.setState({
                    currentPage: 0
                }, () => {
                    this.makeSearch(true, dontKeep);
                });
            }
        }
    }

    render() {

        let cls = "products";

        if (this.state.changing) {
            cls += " products--changing";
        }

        if (!this.props.catalog) {
            cls += " products--no-catalog";
        }
    
        return (
            <div className={cls}>
                <div className="products__sidebar">
                    <Filter
                        keep={this.state.keep}
                        onKeepChange={(v) => this.setState({keep: v})}
                        filterSections={this.state.filterSections}
                        filterItemClick={this.filterItemClick}
                        clearFilterSection={this.clearFilterSection}
                    />
                </div>
                <div className="products__list">
                    <ProductList
                        items={this.state.items}
                        productType={this.props.productType}
                        errorHandler={this.props.errorHandler}
                        invalidSearch={!this.props.searchQuery && !this.props.catalog}
                        searchQuery={this.props.searchQuery}
                        banners={this.props.banners}
                        closeBanner={this.props.closeBanner}
                        selectCatalog={this.props.selectCatalog}
                        openSingleResult={this.state.openSingleResult}
                        onProductClose={()=>{
                            this.setState({openSingleResult: false});
                            window.history.replaceState({ }, "Vyhledávání", '/' + config.searchPageName + '/' +  this.props.productType + '/' + this.props.searchQuery);
                        }}
                    />
                    <Pager
                        currentPage={this.state.currentPage}
                        pages={this.state.pages}
                        count={this.state.count}
                        perPage={this.state.perPage}
                        perPageOptions={this.perPageOptions}
                        goToPage={(num) => this.setState({ currentPage: num }, ()=> this.makeSearch(false, false, true))}
                        onPerPageChange={(num) => this.setState({ perPage: num, currentPage: 0 }, ()=>this.makeSearch(false, false, true))}
                    />
                </div>
            </div>
        )
    }
}

export default Products;
