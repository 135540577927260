import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function FooterLinks(props) {
    return (
        <ul className="footer__links">
            {props.children}
        </ul>
    )
}


function FooterLink(props) {
    let el = (
        <button className="footer__link" onClick={props.onClick}>
            {props.children}
        </button>
    );
    if (props.href) {
        el = (
            <a className="footer__link" href={props.href} target="_blank" rel="noreferrer noopener">
                {props.children}
            </a>
        );
    }
    return (
        <li>
            <FontAwesomeIcon icon="chevron-right" className="footer__linksep" />
            {el}
        </li>
    )
}

class Footer extends React.Component {
    render() {
        return (


            <div className="footer">
                <div className="footer__inside">
                    <div className="footer__left">
                        Copyright © ÚRS CZ a.s., {new Date().getFullYear()}
                    </div>
                    <div className="footer__right">

                        <FooterLinks>
                            {/*<FooterLink onClick={()=>this.props.loadPage("popis-aplikace")}>Popis aplikace</FooterLink>*/}
                            <FooterLink onClick={() => this.props.loadPage("napoveda")}>Nápověda</FooterLink>
                            <FooterLink onClick={() => this.props.loadPage("podpora")}>Podpora</FooterLink>
                            <FooterLink onClick={() => this.props.loadPage("eula")}>Licenční podmínky</FooterLink>
                        </FooterLinks>

                        <FooterLinks>
                            <FooterLink href="https://podminky.urs.cz">Cenové a technické podmínky</FooterLink>
                            <FooterLink href="https://www.cs-urs.cz/tridniky-a-ciselniky/">Třídníky a číselníky</FooterLink>
                            <FooterLink href="https://www.cs-urs.cz/verejne-zakazky/">Veřejné zakázky</FooterLink>
                        </FooterLinks>



                    </div>
                </div>
            </div>

        );
    }
}

export default Footer;
