import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SearchBox extends React.Component {

    constructor(props){
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e){
        if(this.props.onChange){
            this.props.onChange(e.target.value);
        }
    }

    onSubmit(){
        if(this.props.onSubmit){
            this.props.onSubmit(this.props.value);
        }
    }

    render() {
        return (
            <div className="search-box">
                <input
                    value={this.props.value}
                    className="search-box__input"
                    onChange={this.onChange}
                    onKeyPress={(e)=>{if(e.key === 'Enter') this.onSubmit();}}
                    placeholder="Zadejte kód položky nebo hledaný text..."
                />
                <button className="search-box__button" onClick={this.onSubmit}>
                    <FontAwesomeIcon icon="search" className="search-box__button__icon" />
                    <span className="search-box__button__label">
                    Vyhledat
                    </span>
                </button>
            </div>
        );
    }
}

export default SearchBox;
