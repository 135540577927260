import { library } from '@fortawesome/fontawesome-svg-core';
//import { fab } from '@fortawesome/free-brands-svg-icons'
import { faUnlock, faLock, faLockOpen, faChevronDown, faChevronUp, faBuilding, faChevronRight, faEllipsisH, faInfoCircle, faLifeRing, faListUl, faPowerOff, faSearch, faUserTie, faWrench, faCube } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import 'es6-promise/auto'; // IE11
import empty from 'is-empty';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import React from 'react';
import config from '../../config';
import isTest from '../../isTest';
import ListDialog from '../dialogs/ListDialog';
import ErrorHandler from '../utils/ErrorHandler';
import PageLoader from '../utils/PageLoader';
import { AppBar, AppBarItem } from './AppBar';
import { Banners } from './Banners';
import Expiration from './Expiration';
import Footer from './Footer';
import { Header, HeaderItem } from './Header';
import ListButton from './ListButton';
import Loader from './Loader';
import MainBar from './MainBar';
import MainTabs from './MainTabs';
import Products from './Products';
import SearchBox from './SearchBox';
import SearchStatsCatalog from './SearchStatsCatalog';
import { Tab, Tabs } from './Tabs';
import log from 'loglevel';

library.add( faUnlock, faLock, faLockOpen,faChevronDown, faChevronUp, faEllipsisH, faSearch, faListUl, faBuilding, faUserTie, faPowerOff, faInfoCircle, faLifeRing, faWrench, faChevronRight, faCube)


const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight();


class App extends React.Component {

    constructor(props) {
        super(props);

        this.defaultMainTab = {
            
        };

        this.errorHandler = new ErrorHandler(this);

        this.state = this.createInitialState();
        this.state.urls = {
            login: "",
            login_key: "",
            logout: ""
        }

        this.state.loaded = {
            user: false,
            komplety: false,
            banners: false,
            expirations: false
        };

        this.loadPage = this.loadPage.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.makeSearch = this.makeSearch.bind(this);
        this.selectCatalog = this.selectCatalog.bind(this);
        this.closeBanner = this.closeBanner.bind(this);
    }

    createInitialState() {
        return {
            currentMainTab: this.defaultMainTab,
            productType: "constructions",
            listDialogActive: false,
            currentPage: null,
            searchQuery: null,
            tmpSearchQuery: "",
            activeCatalog: null,
            ukazatele: [],
            searchStats: {
                shown: 0,
                total: 0,
                where: null
            },
            komplety: [],
            user: null,
            banners: []
        }
    }

    loadPage(page) {
        this.setState({
            tmpSearchQuery: "",
            currentPage: page,
            activeCatalog: null,
            currentMainTab: this.defaultMainTab
        });
    }

    goToIndex() {
        this.loadPage("index");
    }

    showResults() {
        this.setState({
            currentPage: null
        });
    }

    makeSearch(txt) {
        this.setState({
            currentPage: null,
            activeCatalog: null,
            searchQuery: txt
        });
    }


    componentDidMount() {


        this.loadUrls(() => {


            // user came with api key?
            const parsedUrl = queryString.parse(window.location.search);
            if (parsedUrl && parsedUrl.access_key) {
                window.location.href = this.state.urls.login_key + parsedUrl.access_key;
                return;
            }

            // load initial data
            this.loadUser(() => {

                // load initial page
                let pageName = window.location.pathname.split('/')[1] || 'index';
                this.tryPageLoad(pageName);

                this.setState({ loaded: { ...this.state.loaded, user: true } }, () => {
                    this.loadKomplety();
                });


                // when user clicks on back
                window.addEventListener('popstate', (event) => {
                    if (event.state && event.state.search) {
                        this.setState({
                            currentPage: null,
                        });
                        return;
                    }
                    if (event.state && event.state.name) {
                        this.tryPageLoad(event.state.name);
                        return;
                    }
                });

            });

        });
    }


    tryPageLoad(name) {
        if (name === config.searchPageName) return false;
        if (/^([a-zA-Z-]+)$/.test(name)) {
            this.loadPage(name);
            return true;
        }
        return false;
    }

    loadUser(callback) {
        axios.get('/api/user')
            .then((response) => {
                if (this.errorHandler.check(response)) {
                    let user = response.data;
                    this.loadBanners();
                    this.loadUkazatele();
                    this.loadExpirations();
                    if (!empty(user)) {
                        this.setState({
                            user: user,
                            // currentPage: "index"
                        }, callback);

                    } else {
                        /*_this.setState({
                            user:null,
                            currentPage: "byl-jste-odhlasen"
                        });*/
                        if (!isTest()) {
                            window.location.href = this.state.urls.login;
                        } else {
                            this.setState({
                                user: null,
                               // currentPage: "index"
                            }, callback);
                        }

                    }
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    loadUrls(callback) {
        axios.get('/api/url')
            .then((response) => {
                this.setState({ urls: response.data }, callback);
                this.errorHandler = new ErrorHandler(this, response.data);
            })
            .catch(function (error) {
                console.log(error);
                callback();
            });
    }


    loginUser() {
        window.location.href = this.state.urls.login;
    }

    logoutUser() {
        axios.get('/api/logout')
            .then((response) => {
                window.location.href = this.state.urls.logout;
            })
            .catch((error) => {
                window.location.href = this.state.urls.logout;
                console.log(error);
            });
    }

    loadKomplety() {
        axios.get('/api/json_komplety_vse.php')
            .then((response) => {
                let res = response.data.komplety;
                if (!res) {
                    res = [];
                }
                if (res.length !== 1) {
                    // res.unshift(this.defaultMainTab);
                    this.defaultMainTab = res[0];
                    this.setState({
                        loaded: { ...this.state.loaded, komplety: true },
                        komplety: res,
                        currentMainTab: res[0]
                    });
                } else {
                    this.setState({
                        loaded: { ...this.state.loaded, komplety: true },
                        komplety: res,
                        currentMainTab: res[0]
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    loadUkazatele() {
        axios.get('/api/json_ukazatele.php')
            .then((response) => {
                if (response.data && response.data.items) {
                    this.setState({ ukazatele: response.data.items });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    loadBanners() {
        axios.get('/api/banners')
            .then((response) => {
                let banners = response.data;
                let bannersToShow = [];
                banners.forEach(banner => {
                    if (!Cookies.get("banner-" + banner.id)) {
                        bannersToShow.push(banner);
                    }
                });
                this.setState({
                    loaded: { ...this.state.loaded, banners: true },
                    banners: bannersToShow
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    loadExpirations() {
        axios.get('/api/json_prava_expirace.php')
            .then((response) => {
                const expiration = response.data.expirace || null;
                this.setState({
                    loaded: { ...this.state.loaded, expirations: true },
                    expiration: expiration
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    selectCatalog(catalog, productType) {
        this.setState({
            activeCatalog: catalog,
            listDialogActive: false,
            productType: productType ?? this.state.productType,
            tmpSearchQuery: "",
            searchQuery: null,
            searchStats: null,
            currentPage: null
        })
    }


    closeBanner(id) {
        var d = new Date();
        d.setHours(25, 0, 0, 0);
        Cookies.set("banner-" + id, true, { expires: d });
        this.setState({
            banners: this.state.banners.filter(x => x.id !== id)

    })
    }

    render() {
        
        let cls = "app";
        if (this.state.user) {
            cls += " app--user-logged"
        }

        return (
            <div className={cls}>

                <Loader active={!(this.state.loaded.user && this.state.loaded.komplety && this.state.loaded.banners && this.state.loaded.expirations)} />

                <div className="app__inside">

                    {this.state.user &&
                        <Header>
                            <HeaderItem
                                icon="building"
                                title={this.state.user.company}
                            />
                            <HeaderItem
                                icon="user-tie"
                                title={this.state.user.nickname + " (" + this.state.user.username + ")"}
                            />
                            <HeaderItem
                                icon="power-off"
                                title="Odhlásit"
                                warning
                                responsive
                                onClick={() => this.logoutUser()}
                            />
                        </Header>
                    }
                    {!this.state.user &&
                        <Header>
                            <HeaderItem
                                icon="user-tie"
                                title="Přihlásit"
                                onClick={() => this.loginUser()}
                            />
                        </Header>
                    }

                    {(this.state.user) &&
                        <ListDialog
                            komplet={this.state.currentMainTab.id !== 0 ? this.state.currentMainTab : null}
                            active={this.state.listDialogActive}
                            activeCatalog={this.state.activeCatalog}
                            selectCatalog={this.selectCatalog}
                            productType={this.state.productType}
                            closeDialog={() => this.setState({ listDialogActive: false })}
                            errorHandler={this.errorHandler}
                        />
                    }

                    <div className="app__content">
                        <AppBar
                            goToIndex={this.state.user ? this.goToIndex : null}
                        >
                            {this.state.user &&
                                <React.Fragment>
                                    {this.state.ukazatele && this.state.ukazatele.length > 0 &&
                                        <AppBarItem
                                            title="Ukazatele"
                                            icon="cube"
                                            dropdownMenuItems={
                                                this.state.ukazatele.map((u) => ({
                                                    id: u.id,
                                                    label: u.name,
                                                    target: '_blank',
                                                    linkTo: u.link
                                                }))
                                            }
                                        />
                                    }
                                    {/*
                                    <AppBarItem
                                        active={this.state.currentPage === "popis-aplikace"}
                                        title="Popis aplikace"
                                        icon="info-circle"
                                        onClick={() => this.loadPage("popis-aplikace")}
                                    />
                                    */}
                                    <AppBarItem
                                        active={this.state.currentPage === "napoveda"}
                                        title="Nápověda"
                                        icon="life-ring"
                                        onClick={() => this.loadPage("napoveda")}
                                    />
                                    <AppBarItem
                                        active={this.state.currentPage === "podpora"}
                                        title="Podpora"
                                        icon="wrench"
                                        onClick={() => this.loadPage("podpora")}
                                    />
                                </React.Fragment>
                            }
                        </AppBar>

                        {(this.state.user || isTest()) &&
                            <React.Fragment>

                                {this.state.expiration &&
                                    <Expiration
                                        expiration={this.state.expiration}
                                    />
                                }

                                <Banners
                                    banners={this.state.banners}
                                    closeBanner={this.closeBanner}
                                />

                                {(!this.state.currentPage || this.state.currentPage === "index") &&
                                    <MainBar
                                        right={
                                            <SearchBox
                                                value={this.state.tmpSearchQuery}
                                                onChange={(val) => { this.setState({ tmpSearchQuery: val }) }}
                                                onSubmit={this.makeSearch}
                                            />
                                        }
                                        left={
                                            <ListButton
                                                onClick={() => this.setState({ listDialogActive: !this.state.listDialogActive })}
                                            />
                                        }
                                        top={
                                            <MainTabs
                                                currentTab={this.state.currentMainTab}
                                                onChange={(val) => {
                                                    //log.error(val);
                                                    if (val.id > 0) {
                                                        const c = {
                                                            id: val.id,
                                                            code: null,
                                                            label: val.popis,
                                                            items: [],
                                                        };
                                                        //log.error(c);
                                                        // this.setState({ activeCatalog: c });
                                                        this.selectCatalog(c);
                                                        
                                                    } else {
                                                        this.selectCatalog(this.state.catalog);
                                                        this.setState({ currentMainTab: val });
                                                        this.setState({ productType: "constructions" })
                                                        this.makeSearch("null");
                                                        //tady je akce
                                                        
                                                    }
                                                }}
                                                items={this.state.komplety}
                                            />
                                        }
                                    />
                                }

                                {(!this.state.currentPage) &&
                                    <div>

                                        {this.state.activeCatalog &&
                                            <SearchStatsCatalog
                                                catalog={this.state.activeCatalog}
                                                productType={this.state.productType}
                                                onCatalogClick={() => this.setState({ listDialogActive: true })}
                                            />
                                        }

                                        {!this.state.activeCatalog &&
                                            <Tabs
                                                center
                                                productTabs
                                                currentTab={this.state.productType}
                                                onChange={(val) => this.setState({ productType: val })}
                                            >
                                                <Tab id="constructions" label="Konstrukce" disabled={this.state.currentMainTab.id < -1 } />
                                                <Tab id="materials" label="Materiály" disabled={this.state.currentMainTab.id < -1 } />
                                            </Tabs>
                                        }

                                        <Products
                                            errorHandler={this.errorHandler}
                                            catalog={this.state.activeCatalog}
                                            searchQuery={this.state.searchQuery}
                                            selectCatalog={(cat) => this.selectCatalog(cat, this.state.productType)}
                                            whenSearchingStarts={() => this.setState({ currentPage: null })}
                                            productType={this.state.productType}
                                            komplet={this.state.currentMainTab}
                                            setStats={(stats) => this.setState({ searchStats: stats })}
                                            banners={this.state.banners}
                                            closeBanner={this.closeBanner}
                                            onInit={(tab, searchString)=>{
                                                this.setState({ productType: tab, searchQuery: searchString, tmpSearchQuery: searchString });
                                            }}
                                        />

                                    </div>
                                }
                            </React.Fragment>
                        }


                        <PageLoader
                            currentPage={this.state.currentPage}
                            loadPage={this.loadPage}
                            errorHandler={this.errorHandler}
                        />

                    </div>
                </div>

                {this.state.user &&
                    <Footer
                        loadPage={this.loadPage}
                    />
                }

            </div>
        );
    }
}

export default App;
