import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import classNames from 'classnames';
import Collapsible from 'react-collapsible';

class FilterItem extends React.Component {
    render() {
        const disabledMain = !this.props.count;
        return (
            <label className={classNames({ 'filter__item': true, 'filter__item filter__item--disabled': disabledMain })}>
                {this.props.code &&
                    <div className="filter__item__code">
                        {this.props.code}
                    </div>
                }
                <div className="filter__item__main">
                    <input
                        type="checkbox"
                        className="filter__item__input"
                        checked={this.props.checked || false}
                        onChange={() => { }}
                    />
                    <span
                        onClick={() => this.props.onChange(this.props.id)}
                        className="filter__item__check"
                    />
                    <span
                        className="filter__item__label"
                        onClick={() => this.props.onChange(this.props.id)}
                        dangerouslySetInnerHTML={{ __html: this.props.label }}
                    />
                    <span className="filter__item__count">
                        ({this.props.count})
                    </span>
                    {this.props.square &&
                        <span className="filter__item__squares squares">
                            <span className={`squares__sq squares__sq--${this.props.square}`} />
                        </span>
                    }
                    {this.props.items && (this.props.checked || this.props.totalItemInGroup == 1 ) && !this.props.moreThanOneChecked && 
                        <div className="filter__subs">
                            {this.props.items.map((item) => {
                                const disabled = !item.pocet;
                                return (
                                    <div className={classNames({ 'filter__sub': true, 'filter__sub--disabled': disabled })} key={item.id}>
                                        <label className="filter__sub__check">
                                            <input
                                                type="checkbox"
                                                className="filter__sub__check-input"
                                                checked={item.enabled || false}
                                                disabled={disabled}
                                                onChange={() => this.props.onChange(item.id)}
                                            />
                                            <span className="filter__sub__check-check" />
                                        </label>
                                        <span
                                            className="filter__sub__label"
                                            onClick={() => !disabled ? this.props.onChange(item.id) : null}
                                        >
                                            <span dangerouslySetInnerHTML={{ __html: item.popis }} />
                                            <span className="filter__sub__count">
                                                ({item.pocet || 0})
                                            </span>
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    }
                </div>
            </label>
        )
    }

}

class Filter extends React.Component {

    renderSection(section) {

        let toggleAllVisible = false;
        section.items.forEach(item => {
            if (item.enabled) {
                toggleAllVisible = true;
            }
        });

        const moreThanOneChecked = section.items.filter((x) => x.enabled).length > 1;
        return (
            <div className="filter__togglable" key={section.name}>
                <button
                    className={"filter__toggle-all" + (toggleAllVisible ? " filter__toggle-all--visible" : "")}
                    alt="Zrušit filtr"
                    title="Zrušit filtr"
                    onClick={() => this.props.clearFilterSection(section.name)}
                >
                    X
                </button>
                <Collapsible
                    trigger={section.label}
                    open={true}
                    key={section.name}
                    triggerTagName="div"
                    className="filter__section"
                    openedClassName="filter__section filter__section--active"
                    triggerClassName="filter__section__head"
                    triggerOpenedClassName="filter__section__head"
                    contentInnerClassName="filter__section__items"
                    transitionTime={100}
                    transitionCloseTime={100}
                    easing="ease-out"
                >
                    {section.items.map((item) => {
                        return (
                            <FilterItem
                                key={item.id}
                                id={item.id}
                                label={item.popis}
                                items={item.items}
                                code={item.kod}
                                count={item.pocet}
                                square={item.square}
                                checked={item.enabled}
                                moreThanOneChecked={moreThanOneChecked}
                                totalItemInGroup = {section.items.length}
                                onChange={(id) => {
                                    this.props.filterItemClick(section.name, id)
                                }}
                            />
                        )
                    })}
                </Collapsible>
            </div>
        )

    }

    render() {
        return (
            <Collapsible
                className="filter"
                openedClassName="filter filter--active"
                open={false}
                trigger={
                    <div className="filter__head">
                        Filtrování ve výsledcích
                    </div>
                }
            >
                <div className="filter__contents">
                    {/*
                    <div className={classNames({ 'filter__keep-wrap': true, 'filter__keep-wrap--active': this.props.keep })}>
                        <label className="filter__keep">
                            <input className="filter__keep-input" type="checkbox" value="true" checked={this.props.keep} onChange={(e) => this.props.onKeepChange(!this.props.keep)} />
                            <span className="filter__keep-switch" />
                            Pamatovat filtr
                        </label>
                        {this.props.keep &&
                            <div className="filter__keep-msg">
                                <FontAwesomeIcon className="filter__keep-icon" icon="info-circle" />
                                Vyhledávání je omezeno aktivním filtrem.
                            </div>
                        }
                    </div>
                    */}

                    {this.props.filterSections.map((section) => {
                        return this.renderSection(section);
                    })}
                </div>
            </Collapsible>
        )
    }
}

export default Filter;
