import React from 'react';

function processBlock(block) {
    let el1;
    let el2;

     
    if (block.text) {
        el1 = (
            <div className="notes__item">
                <span className="notes__sep">{block.oddelovnik}</span>
                <span className="notes__text">
                    {block.text}
                </span>
            </div>
        )
    }

    if (block.obsah) {
        el2 = (
            <ul>
                {block.obsah.map((item, index) => {
                    return (
                        <li key={index}>
                            {processBlock(item)}
                        </li>
                    );
                })}
            </ul>
        );

    } 
    return <React.Fragment>{el1}{el2}</React.Fragment>;
}



function notesProcessor(src) {

    //console.log("processing", src);

    if (typeof src === "string") {
        return (
            <div dangerouslySetInnerHTML={src} />
        )
    }

    return <div className="notes">{processBlock(src)}</div>;

}

export default notesProcessor;