import React from 'react';
import Modal from 'react-modal';
import Button from './Button';


let closeTimeout = 500;

Modal.setAppElement('#root');

class Dialog extends React.Component {

    afterOpenModal(){
        document.querySelector(".dialog").scrollTop = 0;
    }

    render() {
        let cls = "dialog";
        if(this.props.wide){
            cls += " dialog--wide";
        }
        return (
            <Modal
                isOpen={this.props.active}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.props.closeDialog}
                className="dialog__modal"
                overlayClassName={cls}
                contentLabel={this.props.label}
                closeTimeoutMS={closeTimeout}
            >
                {this.props.label &&
                    <div className="dialog__head">
                        {this.props.label}
                        <button className="dialog__close" onClick={this.props.closeDialog}>Close</button>
                    </div>
                }
                <div className="dialog__content">
                    {this.props.children}
                </div>

                {this.props.actions && 
                    <div className="dialog__actions">
                        {this.props.actions.map((item, index) => {
                            return(
                                <Button
                                    key={index}
                                    onClick={item.onClick}
                                    label={item.label}
                                />
                            )
                        })}
                    </div>
                }

            </Modal>
        );
    }
}

export default Dialog;